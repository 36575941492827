<template>
  <section
    v-if="cart.items"
    :class="{
      'open': summaryOpen
    }"
    class="cart-summary-floating"
  >
    <div class="cart-summary-floating__wrapper">
      <div
        class="toggle"
        @click.stop="summaryOpen = !summaryOpen"
      />
      <div
        class="close"
        @click.stop="summaryOpen = !summaryOpen"
      >
        <Icon name="material-symbols:close" />
      </div>

      <div class="items">
        <CartItem
          v-for="item in cart?.items"
          :key="item.uid"
          :item="item"
        />
      </div>

      <div class="overview">
        <div class="overview__wrapper">
          <div class="overview__title">
            {{ $t('cart.components.summary.order_overview') }}
          </div>
          <div
            v-if="cart.prices.applied_taxes.length"
            class="overview__tax--mobile"
          >
            <span>{{ $t('cart.components.summary.including_tax') }}</span>
            <strong>{{ $formatPriceWithCurrency(cart.prices.applied_taxes[0].amount.value, cart.prices.applied_taxes[0].amount.currency, storeCode) }}</strong>
          </div>
          <div
            v-if="cart.prices"
            class="overview__tax--mobile"
          >
            <span>{{ $t('cart.components.summary.order_total') }}</span>
            <strong>{{ $formatPriceWithCurrency(cart.prices.grand_total.value, cart.prices.grand_total.currency, storeCode) }}</strong>
          </div>

          <CartCouponForm />
        </div>

        <div class="overview__tax--desktop">
          <div v-if="couponsApplied">
            <span>{{ $t('cart.components.summary.discount_from_coupon') }}</span>
            <strong>-&nbsp;{{ $formatPriceWithCurrency(cart.prices.discounts[0].amount.value, cart.prices.discounts[0].amount.currency, storeCode) }}</strong>
          </div>

          <div v-if="cart.prices.applied_taxes.length">
            <span>{{ $t('cart.components.summary.including_tax') }}</span>
            <strong>{{ $formatPriceWithCurrency(cart.prices.applied_taxes[0].amount.value, cart.prices.applied_taxes[0].amount.currency, storeCode) }}</strong>
          </div>
          <div
            v-if="cart.prices"
            class="overview__price"
          >
            <span>{{ $t('cart.components.summary.order_total') }}</span>
            <strong>{{ $formatPriceWithCurrency(cart.prices.grand_total.value, cart.prices.grand_total.currency, storeCode) }}</strong>
          </div>
        </div>
      </div>

      <div
        v-if="showCheckoutButton"
        :class="{
          'button--disabled': checkoutDisabled,
        }"
        class="button"
        @click="toCheckout"
      >
        {{ $t('cart.components.form.buttons.checkout') }}
      </div>
    </div>
  </section>
</template>

<script setup>
  import { computed } from "vue";

  const cart = useCart();
  const route = useRouter();
  const storeCode = useStoreCode();
  const checkoutDisabled = useCheckoutDisabled();
  const summaryOpen = useState('summaryOpen', () => false);
  const showCheckoutButton = useState('showCheckoutButton', () => false);
  const couponsApplied = computed(() => cart.value?.applied_coupons);
  const { $formatPriceWithCurrency } = useNuxtApp();

  route.isReady().then(() => showCheckoutButton.value = ['init', 'cart'].includes(route.currentRoute.value.name));

  function toCheckout() {
    navigateTo('/customer');
  }
</script>

<style lang="scss" scoped>
  $filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.125));

  .cart-summary-floating {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    z-index: 1;
    position: fixed;
    filter: $filter;

    @include min-screen('medium') {
      left: 60px;
      right: 60px;
      bottom: 30px;
    }

    &.open {
      top: 0;
      filter: none;
      overflow-y: scroll;
      background: var(--body-bg-alt, $cart-item-bg);
      padding-top: var(--cart-summary-floating-padding-top, 60px);

      @include min-screen('medium') {
        top: unset;
        filter: $filter;
        background: unset;
        overflow-y: unset;
        padding-top: unset;
      }

      .items {
        display: block;
        margin-bottom: 60px;

        @include min-screen('medium') {
          display: none;
        }
      }

      .toggle {
        display: none;
      }

      .close {
        display: block;

        @include min-screen('medium') {
          display: none;
        }
      }

      .cart-summary-floating__wrapper {
        background: transparent;
      }
    }

    &__wrapper {
      padding: 0 25px 25px;
      background: var(--white, $white);

      @include min-screen('medium') {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column-gap: 60px;
        padding: 30px 60px 30px 50px;
        clip-path: polygon(0 0, 100% 0%, calc(100% - var(--cart-summary-floating-clip-path)) 100%, 0% 100%);
      }
    }

    .toggle {
      height: 30px;
      cursor: pointer;
      position: relative;

      @include min-screen('medium') {
        display: none;
      }

      &::before {
        content: '';
        top: 10px;
        left: 50%;
        width: 50px;
        height: 3px;
        position: absolute;
        transform: translateX(-50%);
        transition: transform 150ms ease;
        background: var(--medium-grey, $medium-grey);
      }

      &:hover {
        &::before {
          transform: translate(-50%, -2px);
        }
      }
    }

    .close {
      display: none;
      right: 25px;
      font-size: 22px;
      cursor: pointer;
      position: absolute;
      top: var(--cart-summary-close-top, 75px);
    }

    .items {
      display: none;

      @include min-screen('medium') {
        display: none;
      }
    }

    .overview {
      flex: 1;
      display: flex;
      align-items: center;
      grid-column-gap: 50px;
      justify-content: space-between;
      flex-wrap: wrap;
      grid-row-gap: 20px;

      @include min-screen('medium') {
        margin: 0;
        grid-column-gap: 100px;
      }

      &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include min-screen('medium') {
          width: auto;
          display: block;
        }
      }

      &__title {
        margin: 0 0 10px;
        font-size: 1rem;
        font-weight: var(--cart-summary-floating-font-weight, 600);
        text-transform: var(--cart-summary-floating-text-transform, none);

        @include min-screen('medium') {
          font-size: 1.25rem;
        }
      }

      &__tax {
        &--mobile {
          display: grid;
          grid-template-columns: 150px 1fr;
          align-items: center;
          gap: 10px;
          margin-top: 5px;
          font-size: 14px;
          font-weight: normal;
          text-transform: none;
          color: var(--dark-grey, $dark-grey);

          @include min-screen('medium') {
            display: none;
            font-size: 14px;
          }

          strong {
            color: var(--black, $black);
          }
        }

        &--desktop {
          display: none;
          margin-left: auto;

          @include min-screen('medium') {
            display: flex;
            align-items: center;
            grid-column-gap: 60px;
          }

          span {
            display: block;
            margin-bottom: 10px;
            color: var(--dark-grey, $dark-grey);
          }
        }
      }

      &__price {
        strong {
          font-size: 1.5rem;
        }
      }
    }

    .button {
      width: 100%;
      margin-top: 20px;

      @include min-screen('medium') {
        margin-top: 0;
        width: auto;
        min-width: 250px;
      }
    }
  }
</style>
